import { LocalDate } from '@js-joda/core';
import { Frequency, Weekday } from 'rrule';

import { toLocalDate, toRRuleWeekday } from './rrule';
import {
  RecurrenceEndCondition,
  RecurrenceFrequency,
  RecurrenceRepetition,
} from './types';

type CustomRecurrenceState = {
  bymonth: number;
  bymonthday: number;
  bysetpos: number;
  byweekday: Weekday[];
  count: number;
  endType: 'count' | 'never' | 'until';
  freq: RecurrenceFrequency;
  /**
   * The initial date of the "on this date" end condition, which depends on the
   * type of frequency and the initial date. The datepicker for this end
   * condition *must* have this initial value.
   */
  initialUntil: LocalDate;
  interval: number;
  /**
   * bymonthday: "Monthly on the 7th"
   * byweekday: "Monthly on the 3rd Tuesday"
   */
  monthlyType: 'bymonthday' | 'byweekday';
  until: LocalDate;
  /**
   * bymonthday: "Annually on July 23rd"
   * byweekday: "Annually on the 2nd Saturday of April"
   */
  yearlyType: 'bymonthday' | 'byweekday';
};

const getInitialUntil = (
  frequency: Frequency,
  initialDate: LocalDate,
  interval: number,
) => {
  if (frequency === Frequency.DAILY) {
    return initialDate.plusDays(1);
  } else if (frequency === Frequency.WEEKLY) {
    return initialDate.plusWeeks(interval);
  } else if (frequency === Frequency.MONTHLY) {
    return initialDate.plusMonths(1);
  } else {
    return initialDate.plusYears(1);
  }
};

export const stateFromInitialRecurrence = (
  date: LocalDate,
  end: RecurrenceEndCondition,
  repetition: RecurrenceRepetition,
): CustomRecurrenceState => {
  /**
   * Default to the month of the given date,
   * unless it's already defined in the repetition.
   */
  const bymonth =
    'bymonth' in repetition ? repetition.bymonth : date.monthValue();

  /**
   * Default to the day of the month of the given date,
   * unless it's already defined in the repetition.
   */
  const bymonthday =
    'bymonthday' in repetition ? repetition.bymonthday : date.dayOfMonth();

  /**
   * Default to the occurrence of the day in the month,
   * unless it's already defined in the repetition.
   */
  const bysetpos =
    'bysetpos' in repetition
      ? repetition.bysetpos
      : Math.ceil(date.dayOfMonth() / 7);

  /**
   * Default to the weekday of the given date,
   * unless it's already defined in the repetition.
   */
  let byweekday = [toRRuleWeekday(date)];
  if ('byweekday' in repetition) {
    if (Array.isArray(repetition.byweekday)) {
      byweekday = repetition.byweekday;
    } else {
      byweekday = [repetition.byweekday];
    }
  }

  /**
   * If the count is not set in the end condition, default to 2,
   * as it's what we'll show if the user selects count as the end type.
   */
  const count = end.count ?? 2;

  /**
   * Default to 'never', which means the recurrence will never end,
   * unless it's already defined in the repetition as a count or until a date.
   */
  let endType: 'count' | 'never' | 'until' = 'never';
  if (end.count !== null) {
    endType = 'count';
  } else if (end.until !== null) {
    endType = 'until';
  }

  /**
   * If the end condition is set to 'until', use the date from the end
   * condition, otherwise default to the corresponding "initial until"
   * calculated from the frequency and initial date.
   */
  const initialUntil = getInitialUntil(
    repetition.freq,
    date,
    repetition.interval,
  );
  const until = end.until === null ? initialUntil : toLocalDate(end.until);

  /**
   * This is only used for monthly and annual recurrences. We set the default
   * value to what we show in the custom dropdown when the user selects monthly
   * or yearly for the first time.
   */
  const monthlyType =
    'bysetpos' in repetition && 'byweekday' in repetition
      ? 'byweekday'
      : 'bymonthday';

  const yearlyType =
    'bysetpos' in repetition &&
    'byweekday' in repetition &&
    'bymonth' in repetition
      ? 'byweekday'
      : 'bymonthday';

  return {
    bymonth,
    bymonthday,
    bysetpos,
    byweekday,
    count,
    endType,
    freq: repetition.freq,
    initialUntil,
    interval: repetition.interval,
    monthlyType,
    until,
    yearlyType,
  };
};
