import { TaskInstance } from '@dakota/platform-client';
import { createSlice } from '@reduxjs/toolkit';
import dotPropImmutable from 'dot-prop-immutable';

import {
  addAttachmentToInstance,
  addTaskNote,
  deleteAttachment,
  deleteTaskNote,
  listCompletedTasks,
  listTasks,
  updateNonRecurringTaskInstance,
  updateTaskInstance,
  updateTaskStatus,
} from './tasksActions';

export type TasksState = {
  /**
   * True when fetching tasks from the `listTasks` API.
   */
  isLoadingTasks: boolean;
  /**
   * The list of tasks as returned by the `listTasks` API.
   */
  tasks: TaskInstance[];
};

const initialState: TasksState = {
  isLoadingTasks: false,
  tasks: [],
};

export const tasksSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(listCompletedTasks.fulfilled, (state, action) => {
      state.isLoadingTasks = false;
      state.tasks = action.payload;
    });
    builder.addCase(listCompletedTasks.pending, (state) => {
      state.isLoadingTasks = true;
    });
    builder.addCase(listCompletedTasks.rejected, (state) => {
      state.isLoadingTasks = false;
    });
    builder.addCase(listTasks.fulfilled, (state, action) => {
      state.isLoadingTasks = false;
      state.tasks = action.payload;
    });
    builder.addCase(listTasks.pending, (state) => {
      state.isLoadingTasks = true;
    });
    builder.addCase(listTasks.rejected, (state) => {
      state.isLoadingTasks = false;
    });
    builder.addCase(updateTaskInstance.fulfilled, (state, action) => {
      const updatedTask = action.payload;
      /**
       * We need to update the original state when the task is updated.
       */
      state.tasks = state.tasks.map((task) =>
        task.id === updatedTask.id ? new TaskInstance(updatedTask) : task,
      );
    });
    builder.addCase(updateTaskStatus.fulfilled, (state, action) => {
      const updatedTask = action.payload;

      state.tasks = state.tasks.map((task) =>
        task.id === updatedTask.id ? new TaskInstance(updatedTask) : task,
      );
    });
    builder.addCase(
      updateNonRecurringTaskInstance.fulfilled,
      (state, action) => {
        const updatedTask = action.payload;
        state.tasks = state.tasks.map((task) =>
          task.id === updatedTask.id ? new TaskInstance(updatedTask) : task,
        );
      },
    );
    builder.addCase(addTaskNote.fulfilled, (state, action) => {
      const { taskId } = action.meta.arg;
      state.tasks = state.tasks.map((task) =>
        task.id === taskId
          ? dotPropImmutable.set(
              task,
              'attachments.comments',
              (comments: number) => comments + 1,
            )
          : task,
      );
    });
    builder.addCase(deleteTaskNote.fulfilled, (state, action) => {
      const { taskId } = action.meta.arg;
      state.tasks = state.tasks.map((task) =>
        task.id === taskId
          ? dotPropImmutable.set(
              task,
              'attachments.comments',
              (comments: number) => comments - 1,
            )
          : task,
      );
    });
    builder.addCase(addAttachmentToInstance.fulfilled, (state, action) => {
      const { dueDate, seriesId } = action.meta.arg;
      state.tasks = state.tasks.map((task) =>
        task.seriesId === seriesId && task.timeline.scheduledDate === dueDate
          ? {
              ...task,
              attachments: {
                ...task.attachments,
                media: task.attachments.media + 1,
              },
            }
          : task,
      );
    });
    builder.addCase(deleteAttachment.fulfilled, (state, action) => {
      const { dueDate, seriesId } = action.meta.arg;
      state.tasks = state.tasks.map((task) =>
        task.seriesId === seriesId && task.timeline.scheduledDate === dueDate
          ? {
              ...task,
              attachments: {
                ...task.attachments,
                media: task.attachments.media - 1,
              },
            }
          : task,
      );
    });
  },
  initialState,
  name: 'tasks',
  reducers: {},
  selectors: {
    isLoadingTasks: (state: TasksState) => state.isLoadingTasks,
    tasks: (state: TasksState) => state.tasks,
  },
});
