import { TaskInstanceDetails } from '@dakota/platform-client';
import { PlusIcon } from '@heroicons/react/24/outline';
import { Note } from 'components/Note';
import { NewNote } from 'components/Note/NewNote';
import { configSlice } from 'features/config/configSlice';
import {
  addTaskNote,
  deleteTaskNote,
  updateTaskNote,
} from 'features/tasks/tasksActions';
import { tokenSlice } from 'features/token/tokenSlice';
import { userSlice } from 'features/user/userSlice';
import { useCheckPermission } from 'hooks/useCheckPermission';
import { FC, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { Permission } from 'utils/permissions';

type Props = {
  task: TaskInstanceDetails;
};

export const Notes: FC<Props> = ({ task }) => {
  const dispatch = useAppDispatch();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);
  const currentUser = useSelector(userSlice.selectors.currentUser);

  const isAdmin = useCheckPermission(Permission.Admin);
  /** People Managers can edit comments and they have this permission. */
  const isPeopleManager = useCheckPermission(Permission.ManageUsers);

  const notes = task.comments;

  const canAdd = useCheckPermission(Permission.CommentTask);
  const [showAddNote, setShowAddNote] = useState(false);

  /**
   * Admins and People Managers can edit anyone's notes.
   * Other users can only edit their own notes.
   */
  const canUpdateNote = (noteUserId: string) => {
    return isAdmin || isPeopleManager || currentUser.id === noteUserId;
  };

  const createNewNote = (note: string) =>
    dispatch(
      addTaskNote({
        baseUrl,
        dueDate: task.timeline.scheduledDate,
        note,
        seriesId: task.seriesId,
        taskId: task.id,
        token,
      }),
    )
      .unwrap()
      .then((result) => {
        setShowAddNote(false);
        return result;
      });

  const updateNote = useCallback(
    (noteId: string) => (text: string) =>
      dispatch(
        updateTaskNote({
          baseUrl,
          dueDate: task.timeline.scheduledDate,
          id: noteId,
          note: text,
          seriesId: task.seriesId,
          taskId: task.id,
          token,
        }),
      ).unwrap(),
    [
      baseUrl,
      dispatch,
      task.id,
      task.seriesId,
      task.timeline.scheduledDate,
      token,
    ],
  );

  const removeNote = useCallback(
    (noteId: string) => () =>
      dispatch(
        deleteTaskNote({
          baseUrl,
          dueDate: task.timeline.scheduledDate,
          id: noteId,
          seriesId: task.seriesId,
          taskId: task.id,
          token,
        }),
      ).unwrap(),
    [
      baseUrl,
      dispatch,
      task.id,
      task.seriesId,
      task.timeline.scheduledDate,
      token,
    ],
  );

  return (
    <div aria-label='Notes Container' className='flex flex-col gap-4'>
      <div className='text-base font-bold'>Notes</div>
      <div className='flex flex-col gap-6'>
        {notes.map((note) => (
          <Note
            deleteNote={
              canUpdateNote(note.userId) ? removeNote(note.id) : undefined
            }
            key={note.id}
            note={note}
            updateNote={
              canUpdateNote(note.userId) ? updateNote(note.id) : undefined
            }
          />
        ))}
      </div>
      {showAddNote && (
        <NewNote
          onCancel={() => setShowAddNote(false)}
          onSave={createNewNote}
        />
      )}
      {canAdd && !showAddNote && (
        <button
          aria-label='Add note'
          className='flex gap-2 items-center text-green-base text-sm'
          onClick={() => setShowAddNote(true)}
        >
          <PlusIcon className='w-5' /> New Entry
        </button>
      )}
    </div>
  );
};
