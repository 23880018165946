import { Facility, Inspection, TaskInstance } from '@dakota/platform-client';
import Autocomplete from 'components/Autocomplete';
import Chips from 'components/Chip/Chips';
import { ClearAllButton } from 'components/ClearAll';
import { CreateItemButton } from 'components/CreateItemButton';
import { DatePicker } from 'components/DatePicker';
import NoData from 'components/NoData';
import { PageHeader } from 'components/PageHeader';
import SearchInput from 'components/SearchInput';
import { facilitiesSlice } from 'features/facilities/facilitiesSlice';
import { userSlice } from 'features/user/userSlice';
import { useFeatureFlag } from 'hooks/useFeatureFlag';
import { useFilteredAssignments } from 'hooks/useFilteredAssignments';
import { useInternationalization } from 'hooks/useInternationalization';
import { DataStatus, usePageLoadTracking } from 'hooks/usePageLoadTracking';
import { useTaskLoader } from 'hooks/useTaskLoader';
import { useViewToggle } from 'hooks/useViewToggle';
import { InspectionCard } from 'Pages/Inspections/Card';
import { TaskCard } from 'Pages/Tasks/Card';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FeatureFlag } from 'utils/featureFlags';
import { Permission } from 'utils/permissions';

import AssignmentsTable from './AssignmentsTable';
import { getAssignmentStatusLabel } from './helper';
import { AssignmentStatus, AssignmentType } from './types';

const Assignments: FC = () => {
  const { t } = useInternationalization();
  const { t: d } = useInternationalization('filters.dropdowns');

  const isTasksEnabled = useFeatureFlag(FeatureFlag.Tasks);

  const navigate = useNavigate();

  const { openTask, taskViewerComponent } = useTaskLoader();

  const assigneeId = useSelector(userSlice.selectors.currentUser).id;

  const selectableFacilities = useSelector(
    facilitiesSlice.selectors.activeFacilities,
  );

  const { isListView: isListViewEnabled, viewToggleButtons } =
    useViewToggle('My Assignments');
  // List view is mandatory while tasks are disabled
  const isListView = !isTasksEnabled || isListViewEnabled;

  const [selectedFacilities, setSelectedFacilities] = useState<Facility[]>([]);

  const [selectedStatus, setSelectedStatus] = useState<AssignmentStatus[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const initialSelectedType = AssignmentType.All;
  const [selectedType, setSelectedType] = useState(initialSelectedType);

  const { stopTracking } = usePageLoadTracking();

  const statuses = useMemo(
    () => [
      AssignmentStatus.InProgress,
      AssignmentStatus.Overdue,
      AssignmentStatus.Scheduled,
    ],
    [],
  );

  const types = [
    AssignmentType.Inspection,
    AssignmentType.Task,
    AssignmentType.All,
  ];

  const {
    dateRange,
    filteredAssignments,
    hasFilters,
    hookDataLoaded,
    isLoading,
    resetDateRange,
    setDateRange,
  } = useFilteredAssignments({
    assigneeId,
    initialSelectedType,
    searchQuery,
    selectedFacilities,
    selectedStatus,
    selectedType,
    statuses,
  });

  const renderCard = useCallback(
    (assignment: Inspection | TaskInstance) => {
      if (assignment instanceof Inspection) {
        return (
          <InspectionCard
            canOpenInspection
            inspection={assignment}
            key={assignment.id}
            openInspection={() =>
              navigate(
                `/inspections/${assignment.id}?source=${location.pathname}`,
              )
            }
          />
        );
      } else {
        return (
          <TaskCard
            key={assignment.id}
            openTaskInstance={(task) =>
              openTask(task.timeline.scheduledDate, task.seriesId)
            }
            task={assignment}
          />
        );
      }
    },
    [navigate, openTask],
  );

  useEffect(() => {
    if (hookDataLoaded) {
      stopTracking(DataStatus.Fetched);
    }
  }, [hookDataLoaded, stopTracking]);

  const removeFacility = (facility: Facility) => {
    setSelectedFacilities((prev) => prev.filter((f) => f.id !== facility.id));
  };

  const resetFilters = () => {
    setSelectedFacilities([]);
    setSelectedStatus([]);
    resetDateRange();
    setSearchQuery('');
    setSelectedType(initialSelectedType);
  };

  const getAssignmentTypeLabel = (type: AssignmentType) => {
    switch (type) {
      case AssignmentType.All:
        return d('type.all');
      case AssignmentType.Inspection:
        return d('type.inspections');
      case AssignmentType.Task:
        return d('type.tasks');
    }
  };

  return (
    <div className='p-4 sm:p-8'>
      <PageHeader
        scaffold={['My Assignments', 'Dashboard']}
        title='Assignments'
      >
        <CreateItemButton
          buttonText={t('charts.schedule.inspection')}
          link='/manage/inspection-templates'
          permission={Permission.CreateInspection}
        />
        {isTasksEnabled && (
          <CreateItemButton
            buttonText={t('charts.create.task')}
            link='/tasks/scheduled?action=create'
            permission={Permission.CreateTask}
          />
        )}
      </PageHeader>
      <div className='sm:flex sm:justify-between sm:items-center sm:gap-2'>
        <div className='filters-container'>
          <SearchInput
            data-testid='search-input'
            id='search-input'
            onSearch={setSearchQuery}
            value={searchQuery}
          />
          <DatePicker
            asSingle={false}
            id='assignments-date-picker'
            onChange={setDateRange}
            scrollOnFocus={false}
            shortcutsType='next'
            showShortcuts
            value={dateRange}
          />
          <Autocomplete
            className='max-sm:w-full w-44'
            getOptionKey={(facility) => facility.id}
            getOptionLabel={(facility) => facility.name}
            id='assignments-facility-selector'
            label='Facilities'
            multiple
            onChange={setSelectedFacilities}
            options={selectableFacilities}
            value={selectedFacilities}
          />
          <Autocomplete
            className='max-sm:w-full w-44'
            getOptionLabel={getAssignmentStatusLabel}
            id='assignments-status-selector'
            label='Status'
            multiple
            onChange={setSelectedStatus}
            options={statuses}
            value={selectedStatus}
          />
          {isTasksEnabled && (
            <Autocomplete
              className='max-sm:w-full w-36'
              getOptionLabel={getAssignmentTypeLabel}
              id='assignments-type-selector'
              label={d('type.label')}
              onChange={setSelectedType}
              options={types}
              value={selectedType}
            />
          )}
          {hasFilters && <ClearAllButton onClick={resetFilters} />}
        </div>
        {isTasksEnabled && viewToggleButtons}
      </div>
      <Chips
        elements={selectedFacilities}
        getKey={(f) => f.id}
        getLabel={(f) => f.name}
        onRemove={removeFacility}
        title='Facilities'
      />
      {isListView && (
        <AssignmentsTable
          data={filteredAssignments}
          loading={isLoading}
          searchQuery={searchQuery}
        />
      )}
      {!isListView && !isLoading && (
        <div className='space-y-4'>{filteredAssignments.map(renderCard)}</div>
      )}
      {hookDataLoaded && !filteredAssignments.length && !isListView && (
        <NoData />
      )}
      {taskViewerComponent}
    </div>
  );
};

export default Assignments;
