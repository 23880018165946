import {
  DashboardClient,
  ReportRequest,
  ReportsClient,
} from '@dakota/platform-client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ClientData, getClient } from 'features/clientProvider';

export const getComprehensiveStatusReport = createAsyncThunk(
  'reports/getComprehensiveStatusReport',
  async (params: { body: ReportRequest } & ClientData) => {
    const client = getClient(DashboardClient, params);
    const response = await client.getComprehensiveStatusReport(params.body);
    return response.result;
  },
);

export const getResponsesReport = createAsyncThunk(
  'reports/getResponsesReport',
  async (params: { body: ReportRequest } & ClientData) => {
    const client = getClient(ReportsClient, params);
    const response = await client.getResponsesReport(params.body);
    return response.result;
  },
);

export const getFailedItemTypesReport = createAsyncThunk(
  'reports/getFailedItemTypesReport',
  async (params: { body: ReportRequest } & ClientData) => {
    const client = getClient(ReportsClient, params);
    const response = await client.getFailedItemTypesReport(params.body);
    return response.result;
  },
);

export const getResponseDataReport = createAsyncThunk(
  'reports/getResponseDataReport',
  async (params: { body: ReportRequest } & ClientData) => {
    const client = getClient(ReportsClient, params);
    const response = await client.getResponseDataReport(params.body);
    // Here we're making the property fileName required. The BE has confirmed
    // that they will always send us a fileName despite the type declaration
    // describing it as optional.
    return response.result as { fileName: string } & typeof response.result;
  },
);

export const getTaskDataExport = createAsyncThunk(
  'reports/getTaskDataExport',
  async (params: { body: ReportRequest } & ClientData) => {
    const client = getClient(DashboardClient, params);
    const response = await client.getTaskDataExport(params.body);
    return response.result as { fileName: string } & typeof response.result;
  },
);

export const getOnTimeRatioReport = createAsyncThunk(
  'reports/getOnTimeRatioReport',
  async (params: { body: ReportRequest } & ClientData) => {
    const client = getClient(DashboardClient, params);
    const response = await client.getOnTimeRatioReport(params.body);
    return response.result;
  },
);
