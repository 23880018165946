import { Inspection, InspectionStatus } from '@dakota/platform-client';
import { PrinterIcon } from '@heroicons/react/20/solid';
import { Tooltip } from '@mui/material';
import { clsx } from 'clsx';
import { Spinner } from 'components/Spinner';
import { answersSlice } from 'features/answers/answersSlice';
import { configSlice } from 'features/config/configSlice';
import { getInspectionDetails } from 'features/inspections/inspectionActions';
import { tokenSlice } from 'features/token/tokenSlice';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/store';

type Props = {
  hideLabel?: boolean;
  inspection: Inspection;
};

export const PrintAlt: FC<Props> = ({ hideLabel = false, inspection }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);
  const [loading, setLoading] = useState(false);

  const isDisabled =
    inspection.status !== InspectionStatus.InProgress &&
    inspection.status !== InspectionStatus.Completed;

  const answerTypes = useSelector(answersSlice.selectors.answerTypes);
  /**
   * Handles the click event for printing an inspection by fetching all
   * necessary data before redirecting to /print-inspection
   */
  const handlePrintClick = () => {
    if (isDisabled) {
      return;
    }

    setLoading(true);
    void dispatch(getInspectionDetails({ baseUrl, id: inspection.id, token }))
      .then(({ payload: inspectionDetails }) => {
        navigate('/print-inspection', {
          state: {
            answerTypes,
            inspection: inspectionDetails,
          },
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <button
      aria-label='Print Inspection'
      className={clsx(
        'flex items-center gap-3 cursor-pointer text-gray-700',
        'disabled:cursor-not-allowed disabled:text-gray-300',
      )}
      data-testid='print-inspection-button'
      disabled={loading || isDisabled}
      onClick={handlePrintClick}
    >
      {loading ? (
        <Spinner />
      ) : (
        <Tooltip
          arrow
          placement='top'
          title={
            isDisabled
              ? "You cannot print an inspection until it's started"
              : 'Print Inspection'
          }
        >
          <PrinterIcon className='w-4' />
        </Tooltip>
      )}
      {!hideLabel && 'Print'}
    </button>
  );
};
