import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { clsx } from 'clsx';
import Tooltip from 'components/Tooltip';
import { useInternationalization } from 'hooks/useInternationalization';
import { FC } from 'react';

type StartButtonProps = {
  disabled?: boolean;
  onClick: () => void;
};

export const StartButton: FC<StartButtonProps> = ({ disabled, onClick }) => {
  const { t } = useInternationalization('inspections');

  return (
    <div
      className={clsx(
        'flex-none sm:w-24 flex sm:items-center sm:justify-center h-full',
        'sm:min-h-40 sm:border-s border-gray-100 group-hover:border-gray-300',
      )}
    >
      <Tooltip arrow title={disabled ? t('permission.tooltip') : ''}>
        <div>
          <button
            aria-label='Start inspection'
            className={clsx(
              'flex flex-col items-center justify-center w-full h-full',
              'text-gray-400 ',
              'text-xs font-semibold tracking-wider rounded-e-md',
              disabled
                ? 'cursor-not-allowed'
                : ' hover:text-green-base hover:bg-green-lightest',
            )}
            data-testid='start-inspection-button'
            disabled={disabled}
            onClick={onClick}
          >
            <CheckCircleIcon className='w-7 h-7' />
            Start Inspection
          </button>
        </div>
      </Tooltip>
    </div>
  );
};
