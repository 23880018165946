import { TaskInstanceDetails } from '@dakota/platform-client';
import Attachment from 'components/Attachment';
import { Carousel, IMedia } from 'components/Carousel';
import { configSlice } from 'features/config/configSlice';
import {
  deleteAttachment,
  updateAttachment,
} from 'features/tasks/tasksActions';
import { tokenSlice } from 'features/token/tokenSlice';
import { userSlice } from 'features/user/userSlice';
import { useCheckPermission } from 'hooks/useCheckPermission';
import useToast from 'hooks/useToast';
import { AddAttachments } from 'Pages/Tasks/AddAttachments';
import { FC, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { Permission } from 'utils/permissions';

type Props = {
  task: TaskInstanceDetails;
};

export const Attachments: FC<Props> = ({ task }) => {
  const dispatch = useAppDispatch();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);
  const currentUser = useSelector(userSlice.selectors.currentUser);

  const { setErrorMessage } = useToast();

  const canAdd = useCheckPermission(Permission.AddAttachmentToTask);
  const canManageUsers = useCheckPermission(Permission.ManageUsers);
  const isAdmin = useCheckPermission(Permission.Admin);

  const [carouselFileIndex, setCarouselFileIndex] = useState(-1);

  /** Only show files that are not a security risk in the carousel. */
  const carouselFiles = task.media.filter(
    (media) => !media.securityRisk && !!media.filePath,
  ) as IMedia[];

  /**
   * Determines whether the current user can update an attachment.
   * Only an admin, people manager, or the user who created the attachment can
   * update it.
   */
  const canUpdate = useCallback(
    (id: string) => {
      const attachment = task.media.find((m) => m.id === id);

      return isAdmin || canManageUsers || currentUser.id === attachment?.userId;
    },
    [task.media, isAdmin, canManageUsers, currentUser],
  );

  const onEditDescription = (attachmentId: string, description: string) =>
    dispatch(
      updateAttachment({
        attachmentId,
        baseUrl,
        description,
        dueDate: task.timeline.scheduledDate,
        seriesId: task.seriesId,
        taskId: task.id,
        token,
      }),
    )
      .unwrap()
      .then(() => void 0)
      .catch(() => setErrorMessage('Failed to update description'));

  const onDeleteAttachment = async (attachmentId: string) => {
    return dispatch(
      deleteAttachment({
        attachmentId,
        baseUrl,
        dueDate: task.timeline.scheduledDate,
        seriesId: task.seriesId,
        taskId: task.id,
        token,
      }),
    )
      .unwrap()
      .then(() => {
        if (carouselFiles.length === 1) {
          setCarouselFileIndex(-1);
        }
      })
      .catch(() => setErrorMessage('Failed to delete attachment'));
  };

  return (
    <>
      <div className='text-base font-bold pt-2'>Attachments</div>
      <div aria-label='Task Attachments' className='grid grid-cols-2 gap-2'>
        {task.media.length === 0 && (
          <div className='text-sm text-gray-400 italic'>No attachments</div>
        )}
        {task.media.map((attachment) => (
          <Attachment
            attachment={attachment}
            key={attachment.id}
            showInCarousel={() =>
              setCarouselFileIndex(carouselFiles.indexOf(attachment as IMedia))
            }
          />
        ))}
      </div>
      {canAdd && <AddAttachments task={task} />}
      {carouselFileIndex > -1 && carouselFiles.length > 0 && (
        <Carousel
          canDelete={canUpdate}
          canEditDescription={canUpdate}
          initialFileIndex={carouselFileIndex}
          media={carouselFiles}
          onClose={() => setCarouselFileIndex(-1)}
          onDelete={onDeleteAttachment}
          onEditDescription={onEditDescription}
        />
      )}
    </>
  );
};
