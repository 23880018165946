import { Facility, ReportRequest, Summary } from '@dakota/platform-client';
import { DateTimeFormatter, LocalDate } from '@js-joda/core';
import Autocomplete from 'components/Autocomplete';
import Chips from 'components/Chip/Chips';
import { ClearAllButton } from 'components/ClearAll';
import { CreateItemButton } from 'components/CreateItemButton';
import { DatePicker, DatePickerRange } from 'components/DatePicker';
import { PageHeader } from 'components/PageHeader';
import { configSlice } from 'features/config/configSlice';
import { facilitiesSlice } from 'features/facilities/facilitiesSlice';
import { notificationsSlice } from 'features/notifications/notificationsSlice';
import {
  getComprehensiveStatusReport,
  getFailedItemTypesReport,
  getOnTimeRatioReport,
  getResponsesReport,
} from 'features/reports/reportsActions';
import { reportsSlice } from 'features/reports/reportsSlice';
import { tokenSlice } from 'features/token/tokenSlice';
import { useFeatureFlag } from 'hooks/useFeatureFlag';
import { useInternationalization } from 'hooks/useInternationalization';
import { DataStatus, usePageLoadTracking } from 'hooks/usePageLoadTracking';
import { AssignmentType } from 'Pages/Assignments/types';
import { FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { FeatureFlag } from 'utils/featureFlags';
import { Permission } from 'utils/permissions';

import { CompletedCard } from './CompletedCard';
import { CompletedOnTime } from './CompletedOnTime';
import DownloadDashboardButton from './DownloadDashboardButton';
import { FailedResponsesChart } from './FailedResponsesChart';
import { InspectionsOnlyChart } from './InspectionsOnlyChart';
import { OverdueCard } from './OverdueCard';
import { ResultsChart } from './ResultsChart';
import { StatusChart } from './StatusChart';
import SummaryCard from './SummaryCard';

const Charts: FC = () => {
  const { t } = useInternationalization('filters.dropdowns');
  const { t: d } = useInternationalization('charts');

  const isTasksEnabled = useFeatureFlag(FeatureFlag.Tasks);

  const dispatch = useAppDispatch();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);

  const [selectedFacilities, setSelectedFacilities] = useState<Facility[]>([]);

  const facilities = useSelector(facilitiesSlice.selectors.activeFacilities);
  const onTimeRatioReport = useSelector(reportsSlice.selectors.onTimeReport);
  const isLoadingOnTimeRatioReport = useSelector(
    reportsSlice.selectors.isLoadingOnTimeReport,
  );

  const types = [
    AssignmentType.Inspection,
    AssignmentType.Task,
    AssignmentType.All,
  ];

  const [selectedType, setSelectedType] = useState(AssignmentType.Inspection);

  const defaultEndDate = useMemo(() => LocalDate.now(), []);
  const defaultBeginDate = useMemo(
    () => defaultEndDate.minusDays(7),
    [defaultEndDate],
  );

  const [dateRange, setDateRange] = useState<DatePickerRange>({
    begin: defaultBeginDate,
    end: defaultEndDate,
  });

  const validateDateRange = (selectedDateRange: DatePickerRange) => {
    const maxDate = LocalDate.now().plusDays(365);
    const endDate = selectedDateRange.end;
    const beginDate = selectedDateRange.begin;

    // if selected endDate is past maxDate, set endDate to maxDate and warn user
    if (endDate.isAfter(maxDate)) {
      const formattedMaxDate = maxDate.format(
        DateTimeFormatter.ofPattern('MM/dd/yyyy'),
      );
      dispatch(
        notificationsSlice.actions.addStickyMessage({
          actions: [],
          content: `Dashboard can only display up to one year in advance, you may select a date range up to ${formattedMaxDate}.`,
        }),
      );

      setDateRange({ begin: beginDate, end: maxDate });
    } else {
      setDateRange({ begin: beginDate, end: endDate });
    }
  };

  const { stopTracking } = usePageLoadTracking();

  const [allAPIsResolved, setAllAPIsResolved] = useState(false);

  useEffect(() => {
    if (allAPIsResolved) {
      stopTracking(DataStatus.Fetched);
    }
  }, [allAPIsResolved, stopTracking]);

  useEffect(() => {
    if (facilities.length === 0) {
      return;
    }

    const allFacilitiesSelected =
      selectedFacilities.length === facilities.length;

    const body = {
      dateRange: {
        endDate: dateRange.end.toString(),
        startDate: dateRange.begin.toString(),
      },
      facilities: allFacilitiesSelected
        ? []
        : selectedFacilities.map((f) => f.id),
    } as ReportRequest;

    void Promise.all([
      dispatch(getComprehensiveStatusReport({ baseUrl, body, token })),
      dispatch(getResponsesReport({ baseUrl, body, token })),
      dispatch(getFailedItemTypesReport({ baseUrl, body, token })),
      dispatch(getOnTimeRatioReport({ baseUrl, body, token })),
    ]).then(() => setAllAPIsResolved(true));
  }, [
    baseUrl,
    dateRange.begin,
    dateRange.end,
    dispatch,
    facilities.length,
    selectedFacilities,
    token,
  ]);

  const deselectFacility = (facility: Summary) => {
    setSelectedFacilities(
      selectedFacilities.filter((f) => f.id !== facility.id),
    );
  };

  const clearFilters = () => {
    setSelectedType(AssignmentType.Inspection);
    setSelectedFacilities([]);
    setDateRange({
      begin: defaultBeginDate,
      end: defaultEndDate,
    });
  };

  const getAssignmentTypeLabel = (type: AssignmentType) => {
    switch (type) {
      case AssignmentType.All:
        return t('type.all');
      case AssignmentType.Inspection:
        return t('type.inspections');
      case AssignmentType.Task:
        return t('type.tasks');
    }
  };

  return (
    <div className='p-4 sm:p-8'>
      <PageHeader scaffold={['Charts', 'Dashboard']} />
      <div className='filters-container'>
        <DatePicker
          asSingle={false}
          id='charts-date-picker'
          onChange={validateDateRange}
          scrollOnFocus={false}
          showShortcuts
          value={dateRange}
        />
        <Autocomplete
          className='max-sm:w-full w-44'
          getOptionKey={(facility) => facility.id}
          getOptionLabel={(facility) => facility.name}
          id='charts-facility-selector'
          label={t('facility.label')}
          multiple
          onChange={setSelectedFacilities}
          options={facilities}
          value={selectedFacilities}
        />
        {isTasksEnabled && (
          <Autocomplete
            className='max-sm:w-full w-36'
            getOptionLabel={getAssignmentTypeLabel}
            id='charts-type-selector'
            label={t('type.label')}
            onChange={setSelectedType}
            options={types}
            value={selectedType}
          />
        )}
        {(selectedFacilities.length > 0 ||
          !dateRange.begin.equals(defaultBeginDate) ||
          !dateRange.end.equals(defaultEndDate) ||
          selectedType !== AssignmentType.Inspection) && (
          <ClearAllButton
            data-testid='clear-all-button'
            onClick={clearFilters}
          />
        )}
        <Chips
          containerClassName='mt-2'
          elements={selectedFacilities}
          getKey={(f) => f.id}
          getLabel={(f) => f.name}
          onRemove={deselectFacility}
          title='Facilities'
        />
        <div className='hidden sm:flex justify-end items-center flex-grow gap-2'>
          <DownloadDashboardButton
            endDate={dateRange.end}
            selectedFacilityIds={selectedFacilities.map((f) => f.id)}
            startDate={dateRange.begin}
          />
          <CreateItemButton
            buttonText={d('schedule.inspection')}
            link='/manage/inspection-templates'
            permission={Permission.CreateInspection}
          />
          {isTasksEnabled && (
            <CreateItemButton
              buttonText={d('create.task')}
              link='/tasks/scheduled?action=create'
              permission={Permission.CreateTask}
            />
          )}
        </div>
      </div>
      <div className='grid grid-cols-3 gap-2 mt-2 sm:mt-8 mb-2'>
        <CompletedCard selectedType={selectedType} />
        {!isLoadingOnTimeRatioReport && onTimeRatioReport ? (
          <CompletedOnTime
            onTimeRatioReport={onTimeRatioReport}
            selectedType={selectedType}
          />
        ) : (
          <SummaryCard
            loading={isLoadingOnTimeRatioReport}
            title={d('completed.on.time')}
          />
        )}
        <OverdueCard selectedType={selectedType} />
      </div>
      <div
        className='flex flex-col sm:grid sm:grid-cols-3 gap-2'
        data-testid='charts-container'
        id='charts-container'
      >
        <StatusChart selectedType={selectedType} />
        {selectedType === AssignmentType.Task ? (
          <>
            <InspectionsOnlyChart />
            <InspectionsOnlyChart />
          </>
        ) : (
          <>
            <ResultsChart />
            <FailedResponsesChart />
          </>
        )}
      </div>
    </div>
  );
};

export default Charts;
