import { TaskInstance, TaskState } from '@dakota/platform-client';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { clsx } from 'clsx';
import Tooltip from 'components/Tooltip';
import { userSlice } from 'features/user/userSlice';
import { useCheckPermission } from 'hooks/useCheckPermission';
import { useInternationalization } from 'hooks/useInternationalization';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Permission } from 'utils/permissions';

type CompleteButtonProps = {
  onComplete: () => void;
  task: TaskInstance;
};

export const CompleteButton: FC<CompleteButtonProps> = ({
  onComplete,
  task,
}) => {
  const { t } = useInternationalization('tasks');
  const currentUser = useSelector(userSlice.selectors.currentUser);
  const canUpdateTask = useCheckPermission(Permission.UpdateTask);
  if (task.status === TaskState.Completed) {
    return null;
  }
  const isAssignedUser = task.assigneeId === currentUser.id;

  const assignedUserTooltip = isAssignedUser ? t('permission.tooltip') : '';

  return (
    <div
      className={clsx(
        'flex-none sm:w-24 flex sm:items-center sm:justify-center',
        'sm:min-h-40 sm:border-s border-gray-100 group-hover:border-gray-300',
        'max-sm:border-t rounded-e-md',
        canUpdateTask
          ? 'hover:text-green-base hover:bg-green-lightest'
          : 'cursor-not-allowed',
      )}
    >
      <Tooltip
        arrow
        title={
          canUpdateTask ? "Mark this task as 'Completed'" : assignedUserTooltip
        }
      >
        <div>
          <button
            aria-label='Complete task'
            className={clsx(
              'flex flex-col items-center justify-center w-full h-full',
              'text-gray-400 text-xs font-semibold tracking-wider max-sm:py-6',
            )}
            disabled={!canUpdateTask}
            onClick={onComplete}
          >
            <CheckCircleIcon className={clsx('w-7 h-7')} />
            Complete
          </button>
        </div>
      </Tooltip>
    </div>
  );
};
