import {
  AddMediaParams,
  TaskInstance,
  TaskState,
} from '@dakota/platform-client';
import { configSlice } from 'features/config/configSlice';
import { taskEditSlice } from 'features/tasks/taskEditSlice';
import {
  addAttachmentToInstance,
  createTask,
  updateNonRecurringTaskInstance,
  updateTaskInstance,
  updateTaskStatus,
} from 'features/tasks/tasksActions';
import { tokenSlice } from 'features/token/tokenSlice';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';

import useToast from './useToast';

/**
 * Functions to save new tasks and edit existing tasks in the backend.
 */
export const useTaskEdit = () => {
  const dispatch = useAppDispatch();
  const { setErrorMessage, setSuccessMessage } = useToast();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);

  const createTaskRequest = useSelector(
    taskEditSlice.selectors.createTaskRequest,
  );

  const editTaskInstanceRequest = useSelector(
    taskEditSlice.selectors.editTaskInstanceRequest,
  );

  const updateNonRecurringTaskInstanceRequest = useSelector(
    taskEditSlice.selectors.updateNonRecurringTaskInstanceRequest,
  );

  const newAttachments = useSelector(taskEditSlice.selectors.newAttachments);

  const isSingleInstance = useSelector(
    taskEditSlice.selectors.isSingleInstance,
  );

  /**
   * Add current attachments to a task instance. Returns
   * an array of the filenames of the files that failed to upload. If all
   * uploads are successful (including when there is no file to upload), it
   * returns an empty array.
   */
  const addAttachmentsToInstance = async (
    seriesId: string,
    dueDate: string,
    onUploading?: (filename: string) => void,
    onSuccess?: (filename: string) => void,
  ) =>
    Promise.all(
      newAttachments.map(async (attachment) => {
        const options = {
          description: attachment.description,
          mediaFile: {
            data: attachment.mediaFile.data as File,
            fileName: attachment.mediaFile.fileName,
          },
        } as AddMediaParams;

        onUploading?.(attachment.mediaFile.fileName);

        return await dispatch(
          addAttachmentToInstance({
            baseUrl,
            dueDate,
            options,
            seriesId,
            token,
          }),
        )
          .unwrap()
          .then(() => onSuccess?.(attachment.mediaFile.fileName))
          .then(() => undefined)
          .catch(() => attachment.mediaFile.fileName);
      }),
    ).then((promises) => promises.filter((result) => result !== undefined));

  const createNewTask = async () =>
    await dispatch(
      createTask({ baseUrl, task: createTaskRequest, token }),
    ).unwrap();

  const editTaskInstance = () =>
    dispatch(
      updateTaskInstance({ baseUrl, ...editTaskInstanceRequest, token }),
    ).unwrap();

  const editNonRecurringTaskInstance = () =>
    dispatch(
      updateNonRecurringTaskInstance({
        baseUrl,
        ...updateNonRecurringTaskInstanceRequest,
        token,
      }),
    ).unwrap();

  const saveTask = async () => {
    const saveAction = isSingleInstance
      ? editNonRecurringTaskInstance
      : editTaskInstance;

    return saveAction()
      .then(() => setSuccessMessage('Task updated successfully'))
      .catch(() => setErrorMessage('Failed to update task'));
  };

  const updateStatus = (task: TaskInstance, status: TaskState) =>
    dispatch(
      updateTaskStatus({
        baseUrl,
        body: { status },
        dueDate: task.timeline.scheduledDate,
        seriesId: task.seriesId,
        token,
      }),
    ).unwrap();

  return {
    addAttachmentsToInstance,
    createNewTask,
    editTaskInstance,
    saveTask,
    updateStatus,
  };
};
