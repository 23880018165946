import { ClipboardIcon } from '@heroicons/react/24/outline';
import { DateTimeFormatter, LocalDate } from '@js-joda/core';
import Button from 'components/Button';
import SidePanel from 'components/SidePanel';
import Confirmation from 'components/SimpleConfirmation';
import { WarningMessage } from 'components/WarningMessage';
import { notificationsSlice } from 'features/notifications/notificationsSlice';
import { taskEditSlice } from 'features/tasks/taskEditSlice';
import { useTaskEdit } from 'hooks/useTaskEdit';
import useToast from 'hooks/useToast';
import { FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { datetime, RRule } from 'rrule';
import { useAppDispatch } from 'store/store';

import { Assignee } from './fields/Assignee';
import { Attachments } from './fields/Attachments';
import { Description } from './fields/Description';
import { DueDate } from './fields/DueDate';
import { Facility } from './fields/Facility';
import { Note } from './fields/Note';
import { TaskPriority } from './fields/Priority';
import { Recurrence } from './fields/Recurrence';
import { Title } from './fields/Title';
import { Zone } from './fields/Zone';

type CreateTaskProps = {
  onClose: (taskCreated?: boolean) => void;
};

export const CreateTask: FC<CreateTaskProps> = ({ onClose }) => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { addAttachmentsToInstance, createNewTask } = useTaskEdit();
  const hasUnsavedChanges = useSelector(
    taskEditSlice.selectors.hasUnsavedChanges,
  );

  const { setErrorMessage, setSuccessMessage } = useToast();

  const canSave = useSelector(taskEditSlice.selectors.canSave);
  const date = useSelector(taskEditSlice.selectors.date);
  const recurrence = useSelector(taskEditSlice.selectors.recurrence);

  const { firstInstanceDate, isRecurring } = useMemo(() => {
    const localDate = LocalDate.parse(date);
    const ruleObj = new RRule({
      ...RRule.parseString(recurrence),
      dtstart: datetime(
        localDate.year(),
        localDate.monthValue(),
        localDate.dayOfMonth(),
      ),
    });

    // Obtain the first instance only to correctly display the due date.
    // It is VERY important we stop the iterator after the first instance,
    // otherwise a call to `ruleObj.all()` can be very slow.
    // See https://github.com/jkbrzt/rrule?tab=readme-ov-file#rruleprototypealliterator
    const firstInstance = ruleObj.all((_, i) => i < 1)[0];

    return {
      firstInstanceDate: LocalDate.of(
        firstInstance.getUTCFullYear(),
        firstInstance.getUTCMonth() + 1,
        firstInstance.getUTCDate(),
      ).format(DateTimeFormatter.ofPattern('MM/dd/yyyy')),
      isRecurring: ruleObj.options.count !== 1,
    };
  }, [date, recurrence]);

  const [saving, setSaving] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    // Clear the edit state when we open the create dialog
    dispatch(taskEditSlice.actions.clear());
  }, [dispatch]);

  const clearAndClose = (taskCreated?: boolean) => {
    dispatch(taskEditSlice.actions.clear());
    onClose(taskCreated);
  };

  const checkChangesAndClose = () => {
    if (hasUnsavedChanges) {
      setShowConfirmation(true);
    } else {
      clearAndClose();
    }
  };

  const goToCreatedTask = (seriesId: string, startDate: string) => {
    dispatch(taskEditSlice.actions.clear());
    setSearchParams({ ...searchParams, date: startDate, seriesId });
  };

  const createTaskWithAttachments = () => {
    setSaving(true);

    createNewTask()
      .then(async (taskSeries) => ({
        failedFiles: await addAttachmentsToInstance(
          taskSeries.id,
          taskSeries.startDate,
        ),
        taskSeries,
      }))
      .then((result) => {
        if (result.failedFiles.length === 0) {
          setSuccessMessage('Task created successfully');
        } else {
          const message = (
            <>
              Your Task was created successfully. However, the following
              attachment(s) failed:
              <ul className='list-inside list-disc'>
                {result.failedFiles.map((file) => (
                  <li key={file}>{file}</li>
                ))}
              </ul>
            </>
          );

          const actions = [
            {
              onClick: () =>
                goToCreatedTask(
                  result.taskSeries.id,
                  result.taskSeries.startDate,
                ),
              text: 'Open Task',
            },
          ];

          dispatch(
            notificationsSlice.actions.addStickyMessage({
              actions,
              content: message,
            }),
          );
        }
        clearAndClose(true);
      })
      // @TODO: Update error messages to handle all possible error responses as
      // well as use keys for translated strings
      .catch((e: unknown) => {
        if (e === 400) {
          setErrorMessage('Recurrence is invalid, failed to create task');
        } else setErrorMessage('Failed to create task');
      })
      .finally(() => setSaving(false));
  };

  return (
    <SidePanel
      isOpen
      onClose={checkChangesAndClose}
      PanelTitle={
        <div className='flex text-green-base gap-2'>
          <ClipboardIcon aria-label='Clipboard Icon' className='w-6' />
          <p className='text-lg font-medium'>Create Task</p>
        </div>
      }
      size='lg'
    >
      <div className='h-full flex flex-col'>
        <div className='flex-1 flex flex-col w-full overflow-y-auto p-6 gap-4'>
          <div className='grid grid-cols-[200px,300px] text-gray-700 gap-y-4 justify-between'>
            <Title />
            <Facility />
            <Zone />
            <Assignee />
            <TaskPriority />
            <DueDate />
            <Recurrence />
            <Description />
          </div>
          {isRecurring && (
            <WarningMessage variant='light'>
              You are creating a recurring task series. Notes and attachments
              added here will be added to the first task of the series, due on{' '}
              {firstInstanceDate}.
            </WarningMessage>
          )}
          <div className='flex flex-col gap-4'>
            <Note />
          </div>
          <div className='flex flex-col gap-4'>
            <Attachments />
          </div>
        </div>
        <div className='flex-none h-16 flex gap-2.5 px-6 py-4 border-t border-gray-200'>
          <Button
            aria-label='Save'
            disabled={!canSave}
            loading={saving}
            onClick={createTaskWithAttachments}
          >
            {saving ? 'Saving...' : 'Save'}
          </Button>
          <Button disabled={saving} onClick={checkChangesAndClose} secondary>
            Cancel
          </Button>
        </div>
      </div>
      {showConfirmation && (
        <Confirmation
          cancelText='Return to form'
          confirmText='Discard'
          onCancel={() => setShowConfirmation(false)}
          onConfirm={() => {
            setShowConfirmation(false);
            onClose();
          }}
        >
          You have unsaved changes. Are you sure you want to discard them?
        </Confirmation>
      )}
    </SidePanel>
  );
};
