import {
  Inspection,
  InspectionStatus,
  TaskInstance,
} from '@dakota/platform-client';
import {
  ClipboardDocumentCheckIcon,
  PencilSquareIcon,
} from '@heroicons/react/24/outline';
import { GridColDef } from '@mui/x-data-grid';
import { clsx } from 'clsx';
import { AttachmentCounter } from 'components/AttachmentCounter';
import { AssigneeAvatar } from 'components/Avatar';
import { ZoneCell } from 'components/Inspections/ZoneCell';
import { OverdueIndicator } from 'components/OverdueIndicator';
import { PriorityIcon } from 'components/PriorityIcon';
import { MuiGridWrapper } from 'components/Table/GridWrapper';
import { MobileTable } from 'components/Table/mobileTable';
import Tooltip from 'components/Tooltip';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { useDateFormat } from 'hooks/useDateFormat';
import { useTaskLoader } from 'hooks/useTaskLoader';
import { useUsers } from 'hooks/useUsers';
import { StatusDropdown } from 'Pages/Tasks/StatusDropdown';
import { getPriorityLabel } from 'Pages/Tasks/types';
import { FC } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getHighlighter } from 'utils/highlighter';
import { getStatusLabel } from 'utils/inspectionStatusLabel';

type AssignmentRow = Inspection | TaskInstance;

interface AssignmentsTableProps {
  data: AssignmentRow[];
  loading?: boolean;
  /**
   * If provided, it will highlight the search query in a few columns.
   * @default `undefined`
   */
  searchQuery?: string;
}

const AssignmentsTable: FC<AssignmentsTableProps> = ({
  data,
  loading,
  searchQuery,
}) => {
  const { isMobile } = useBreakpoints();

  const highlight = getHighlighter(searchQuery ?? '');

  const { getUserSummary } = useUsers();
  const { formatBackendDate } = useDateFormat();
  const navigate = useNavigate();

  const { openTask, taskParams, taskViewerComponent } = useTaskLoader();

  // Type guard functions to identify whether a row is an Inspection or
  // TaskInstance. This helps safely distinguish between these two types in
  // `AssignmentRow`.
  const isInspection = (row: AssignmentRow): row is Inspection => {
    return row instanceof Inspection;
  };

  const isTaskInstance = (row: AssignmentRow): row is TaskInstance => {
    return row instanceof TaskInstance;
  };

  const openAssignment = (row: AssignmentRow) => {
    if (isInspection(row)) {
      navigate(`/inspections/${row.id}?source=${location.pathname}`);
    } else if (isTaskInstance(row)) {
      openTask(row.timeline.scheduledDate, row.seriesId);
    }
  };

  const typeColumn: GridColDef<AssignmentRow> = {
    align: 'center',
    display: 'flex',
    field: 'type',
    headerAlign: 'center',
    headerName: '',
    renderCell: (params) =>
      isInspection(params.row) ? (
        <Tooltip arrow placement='bottom' title='Inspection'>
          <div aria-label='Inspection'>
            <ClipboardDocumentCheckIcon className='w-5 h-5 max-sm:hidden' />
          </div>
        </Tooltip>
      ) : (
        <Tooltip arrow placement='bottom' title='Task'>
          <div aria-label='Task'>
            <PencilSquareIcon className='w-5 h-5 max-sm:hidden' />
          </div>
        </Tooltip>
      ),
    resizable: false,
    width: 50,
  };

  const titleColumn: GridColDef<AssignmentRow> = {
    cellClassName: 'flex-col !justify-center !items-start gap-1',
    display: 'flex',
    field: 'title',
    flex: 1,
    headerName: 'Title',
    minWidth: 300,
    renderCell: (params) => {
      const row = params.row;
      const link = isInspection(row)
        ? `/inspections/${row.id}?source=${location.pathname}`
        : `/dashboard/assignments?${taskParams(
            row.timeline.scheduledDate,
            row.seriesId,
          )}`;

      const title = isInspection(row) ? row.form.name : row.title;

      const isOverdue =
        (isInspection(row) && row.status === InspectionStatus.Overdue) ||
        (isTaskInstance(row) && row.overdue);

      return (
        <>
          <Link
            className={clsx('text-green-base hover:text-green-darker w-full', {
              truncate: !isMobile,
            })}
            to={link}
          >
            {highlight(title)}
          </Link>
          {isOverdue && <OverdueIndicator />}
        </>
      );
    },
  };

  const assigneeColumn: GridColDef<AssignmentRow> = {
    align: 'center',
    display: 'flex',
    field: 'assignee',
    headerAlign: 'center',
    headerName: 'Assignee',
    minWidth: 100,
    renderCell: (params) => {
      const row = params.row;
      const userId = isInspection(row) ? row.userId : row.assigneeId;

      if (isMobile) {
        // Suppressing the warning because the fragment ensures a consistent
        // return type (JSX.Element) without introducing unnecessary wrapping
        // elements like <div> or <span>.
        // eslint-disable-next-line sonarjs/jsx-no-useless-fragment
        return <>{getUserSummary(userId)?.name}</>;
      } else {
        return <AssigneeAvatar user={getUserSummary(userId)} />;
      }
    },
  };

  const dueDateColumn: GridColDef<AssignmentRow> = {
    field: 'dueDate',
    headerName: 'Due Date',
    renderCell: (params) =>
      formatBackendDate(params.row.timeline.scheduledDate),
    resizable: false,
    width: 100,
  };

  const facilityColumn: GridColDef<AssignmentRow> = {
    field: 'facility',
    flex: 1,
    headerName: 'Facility',
    maxWidth: 300,
    minWidth: 150,
    renderCell: (params) => highlight(params.row.facility.name),
  };

  const zoneColumn: GridColDef<AssignmentRow> = {
    field: 'zone',
    headerName: 'Zone',
    minWidth: 150,
    renderCell: (params) => {
      const row = params.row;
      if (isInspection(row)) {
        return <ZoneCell name={row.form.name} zones={row.zones} />;
      } else if (isTaskInstance(row) && row.zone) {
        return <div aria-label='Zone name'>{highlight(row.zone.name)}</div>;
      }
    },
  };

  const metadataColumn: GridColDef<AssignmentRow> = {
    align: 'center',
    display: 'flex',
    field: 'metadata',
    headerAlign: 'center',
    headerName: '',
    minWidth: 260,
    renderCell: (params) => {
      const { comments, media } = params.row.attachments;

      return (
        <div className='flex items-center gap-2'>
          <PriorityIcon priority={params.row.priority} />
          {isInspection(params.row) ? (
            <span className='min-w-36 text-left pl-2'>
              {getStatusLabel(params.row.status)}
            </span>
          ) : (
            <StatusDropdown task={params.row} />
          )}
          <AttachmentCounter
            comments={comments}
            media={media}
            onClick={() => openAssignment(params.row)}
          />
        </div>
      );
    },
  };

  const attachmentsColumn: GridColDef<AssignmentRow> = {
    align: 'center',
    display: 'flex',
    field: 'attachments',
    headerName: 'Attachments',
    renderCell: (params) => {
      const { comments, media } = params.row.attachments;
      return (
        <AttachmentCounter
          comments={comments}
          media={media}
          onClick={() => openAssignment(params.row)}
        />
      );
    },
    resizable: false,
    width: 80,
  };

  const columns = [
    typeColumn,
    titleColumn,
    assigneeColumn,
    dueDateColumn,
    facilityColumn,
    zoneColumn,
    metadataColumn,
  ];

  const typeColumnMobile: GridColDef<AssignmentRow> = {
    field: 'type',
    headerName: 'Type',
    renderCell: (params) => (isInspection(params.row) ? 'Inspection' : 'Task'),
  };

  const priorityColumnMobile: GridColDef<AssignmentRow> = {
    field: 'priority',
    headerName: 'Priority',
    renderCell: (params) => getPriorityLabel(params.row.priority),
  };

  const statusColumnMobile: GridColDef<AssignmentRow> = {
    field: 'status',
    headerName: 'Status',
    renderCell: (params) =>
      isInspection(params.row) ? (
        getStatusLabel(params.row.status)
      ) : (
        <StatusDropdown task={params.row} />
      ),
  };

  const mobileColumns = [
    typeColumnMobile,
    titleColumn,
    dueDateColumn,
    facilityColumn,
    zoneColumn,
    priorityColumnMobile,
    assigneeColumn,
    statusColumnMobile,
    attachmentsColumn,
  ];

  return (
    <>
      {isMobile ? (
        <MobileTable columns={mobileColumns} loading={loading} rows={data} />
      ) : (
        <MuiGridWrapper columns={columns} loading={loading} rows={data} />
      )}
      {taskViewerComponent}
    </>
  );
};

export default AssignmentsTable;
