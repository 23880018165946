import {
  Inspection,
  InspectionStatus,
  Summary,
  TaskInstance,
  TaskState,
} from '@dakota/platform-client';
import { LocalDate } from '@js-joda/core';
import { DatePickerRange } from 'components/DatePicker';
import { configSlice } from 'features/config/configSlice';
import { listInspections } from 'features/inspections/inspectionActions';
import { inspectionSlice } from 'features/inspections/inspectionSlice';
import { listTasks } from 'features/tasks/tasksActions';
import { tasksSlice } from 'features/tasks/tasksSlice';
import { tokenSlice } from 'features/token/tokenSlice';
import Fuse from 'fuse.js';
import {
  getItemAssignmentStatus,
  mapToInspectionStatus,
  mapToSearchStatus,
} from 'Pages/Assignments/helper';
import { AssignmentStatus, AssignmentType } from 'Pages/Assignments/types';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import { compareAssignments } from 'utils/compare';
import { FeatureFlag } from 'utils/featureFlags';

import { useDateRange } from './useDateRange';
import { useFeatureFlag } from './useFeatureFlag';

type UseFilteredAssignmentsParams = {
  assigneeId: string;
  /**
   * Initial date range for the date picker.
   * @default range from today to 7 days from now
   */
  initialDateRange?: DatePickerRange;
  /**
   * The initial selected type
   * @default All
   */
  initialSelectedType?: AssignmentType;
  /**
   * If present, filter by text search
   * @default `undefined`
   */
  searchQuery?: string;
  /**
   * If present, filter by facility
   * @default `undefined`
   */
  selectedFacilities?: Summary[];
  /**
   * If present, filter by type
   * @default `undefined`
   */
  selectedType?: AssignmentType;
  /**
   * Statuses to show in the table. This object *must* be memoized in the
   * caller.
   */
  statuses: AssignmentStatus[];
} & (
  | {
      /**
       * When we use an array for selected statuses, we always default to "all
       * statuses", so we don't need an initial value.
       */
      initialSelectedStatus?: never;
      /**
       * If present, filter by the statuses in the array. If not, use all statuses.
       * @default `undefined`
       */
      selectedStatus?: AssignmentStatus[];
    }
  | {
      initialSelectedStatus: AssignmentStatus;
      /**
       * If present, filter by status
       * @default `undefined`
       */
      selectedStatus?: AssignmentStatus;
    }
);

export const useFilteredAssignments = ({
  assigneeId,
  initialDateRange = {
    begin: LocalDate.now(),
    end: LocalDate.now().plusDays(7),
  },
  initialSelectedStatus,
  initialSelectedType,
  searchQuery = undefined,
  selectedFacilities = undefined,
  selectedStatus = undefined,
  selectedType = undefined,
  statuses,
}: UseFilteredAssignmentsParams) => {
  const dispatch = useAppDispatch();
  const baseUrl = useSelector(configSlice.selectors.backend);
  const token = useSelector(tokenSlice.selectors.token);
  const allInspections = useSelector(inspectionSlice.selectors.inspections);
  const allTasks = useSelector(tasksSlice.selectors.tasks);

  const isTasksEnabled = useFeatureFlag(FeatureFlag.Tasks);

  const [filteredAssignments, setFilteredAssignments] = useState<
    (Inspection | TaskInstance)[]
  >([]);
  const [hookDataLoaded, setHookDataLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { dateRange, resetDateRange, setDateRange } =
    useDateRange(initialDateRange);

  const hasFilters =
    !!searchQuery ||
    !dateRange.begin.equals(initialDateRange.begin) ||
    !dateRange.end.equals(initialDateRange.end) ||
    !!selectedFacilities?.length ||
    (Array.isArray(selectedStatus) && !!selectedStatus.length) ||
    (!Array.isArray(selectedStatus) &&
      selectedStatus !== initialSelectedStatus) ||
    selectedType !== initialSelectedType;

  // Determines if the inspection's scheduled date falls within the selected
  // date range
  const isInDateRange = useCallback(
    (date: string) => {
      const parsedDate = LocalDate.parse(date);
      return (
        !parsedDate.isBefore(dateRange.begin) &&
        !parsedDate.isAfter(dateRange.end)
      );
    },
    [dateRange],
  );

  const isAtFacility = useCallback(
    (item: Inspection | TaskInstance) =>
      !selectedFacilities?.length ||
      selectedFacilities.some((facility) => facility.id === item.facility.id),
    [selectedFacilities],
  );

  // Determines if the item's status matches the selected status
  const matchesStatus = useCallback(
    (item: Inspection | TaskInstance) => {
      if (!selectedStatus) {
        return true;
      }
      const itemStatus = getItemAssignmentStatus(item);

      if (Array.isArray(selectedStatus)) {
        return !selectedStatus.length || selectedStatus.includes(itemStatus);
      }

      return selectedStatus === itemStatus;
    },
    [selectedStatus],
  );

  // Filters inspections based on current criteria
  const filterInspections = useCallback(
    (inspections: Inspection[], skipDateCheck = false) => {
      if (selectedType === AssignmentType.Task) {
        return [];
      }

      return inspections.filter(
        (inspection) =>
          matchesStatus(inspection) &&
          isAtFacility(inspection) &&
          (skipDateCheck || isInDateRange(inspection.timeline.scheduledDate)),
      );
    },
    [selectedType, matchesStatus, isAtFacility, isInDateRange],
  );

  // Filters tasks based on current criteria
  const filterTasks = useCallback(
    (tasks: TaskInstance[], skipDateCheck = false) => {
      if (selectedType === AssignmentType.Inspection) {
        return [];
      }

      return tasks.filter((task) => {
        const isOverdue = task.overdue;
        const isScheduled = task.status === TaskState.Scheduled;
        const isInProgress = task.status === TaskState.InProgress;

        // If Overdue status is selected and this task is overdue, show it
        // regardless of the date range
        if (
          Array.isArray(selectedStatus) &&
          selectedStatus.includes(AssignmentStatus.Overdue) &&
          isOverdue
        ) {
          return true;
        }

        // Include tasks that are scheduled or in progress, even if overdue
        if (
          Array.isArray(selectedStatus) &&
          ((selectedStatus.includes(AssignmentStatus.Scheduled) &&
            isScheduled) ||
            (selectedStatus.includes(AssignmentStatus.InProgress) &&
              isInProgress))
        ) {
          return true;
        }

        return (
          matchesStatus(task) &&
          (skipDateCheck || isInDateRange(task.timeline.scheduledDate)) &&
          isAtFacility(task)
        );
      });
    },
    [selectedType, selectedStatus, matchesStatus, isInDateRange, isAtFacility],
  );

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const inspectionStatuses = mapToInspectionStatus(statuses);
      const taskStatuses = mapToSearchStatus(statuses);
      try {
        await Promise.all([
          dispatch(
            listInspections({
              baseUrl,
              status: inspectionStatuses,
              token,
              userId: assigneeId,
            }),
          ).unwrap(),
          isTasksEnabled
            ? dispatch(
                listTasks({
                  baseUrl,
                  dateRange_endDate: dateRange.end.toString(),
                  dateRange_startDate: dateRange.begin.toString(),
                  status: taskStatuses,
                  token,
                  userId: assigneeId,
                }),
              ).unwrap()
            : Promise.resolve(),
        ]);
        setHookDataLoaded(true);
      } catch {
        setFilteredAssignments([]);
      } finally {
        setIsLoading(false);
      }
    };

    void fetchData();
  }, [
    dispatch,
    baseUrl,
    token,
    dateRange,
    assigneeId,
    statuses,
    isTasksEnabled,
  ]);

  useEffect(() => {
    // Get all overdue and in progress inspections regardless of date range
    const overdueAndInProgressInspections = filterInspections(
      allInspections.filter(
        (inspection) =>
          inspection.status === InspectionStatus.Overdue ||
          inspection.status === InspectionStatus.InProgress,
      ),
      true,
    );

    // Get all overdue and in progress tasks regardless of date range
    const overdueAndInProgressTasks = filterTasks(
      allTasks.filter(
        (task) => task.overdue || task.status === TaskState.InProgress,
      ),
      true,
    );

    // Filter all inspections with the complete criteria
    const filteredInspections = filterInspections(allInspections);

    // Filter all tasks with the complete criteria
    const filteredTasks = filterTasks(allTasks);

    // Combine overdue and in progress inspections with filtered inspections
    const combinedInspections = [
      ...overdueAndInProgressInspections,
      ...filteredInspections.filter(
        (inspection) =>
          inspection.status !== InspectionStatus.Overdue &&
          inspection.status !== InspectionStatus.InProgress,
      ),
    ];

    // Combine overdue and in progress tasks with filtered tasks
    const combinedTasks = [
      ...overdueAndInProgressTasks,
      ...filteredTasks.filter(
        (task) => task.status !== TaskState.InProgress && !task.overdue,
      ),
    ];

    // Combine inspections and tasks
    let combinedData = [...combinedInspections, ...combinedTasks].sort(
      compareAssignments,
    );

    if (searchQuery) {
      const fuse = new Fuse(combinedData, {
        findAllMatches: true,
        ignoreLocation: true,
        keys: [
          { name: 'title', weight: 1 }, // For Tasks
          { name: 'form.name', weight: 1 }, // For Inspections
          { name: 'facility.name', weight: 0.8 },
          { name: 'status', weight: 0.8 },
        ],
        shouldSort: true,
        threshold: 0.2,
        useExtendedSearch: true,
      });

      combinedData = fuse.search(searchQuery).map((result) => result.item);
    }

    setFilteredAssignments(combinedData);
  }, [allInspections, allTasks, filterInspections, filterTasks, searchQuery]);

  return {
    dateRange,
    filteredAssignments,
    hasFilters,
    hookDataLoaded,
    isLoading,
    resetDateRange,
    setDateRange,
  };
};
