import {
  useAppInsightsContext,
  useTrackEvent,
} from '@microsoft/applicationinsights-react-js';
import { ViewToggle } from 'components/ViewToggle';
import { useEffect, useMemo } from 'react';
import { isTelemetryEnabled } from 'telemetry/service';
import { useSessionStorage } from 'usehooks-ts';

export const useViewToggle = (key: string) => {
  const appInsights = useAppInsightsContext();
  const [isListView, setIsListView] = useSessionStorage(key, false);

  const trackEvent = useTrackEvent(appInsights, 'Toggle-Table-View', {});

  useEffect(() => {
    if (isTelemetryEnabled()) {
      trackEvent({
        'Screen.Name': key,
        'View.Type': isListView ? 'list' : 'card',
      });
    }
  }, [isListView, key, trackEvent]);

  const viewToggleButtons = useMemo(() => {
    return <ViewToggle listView={isListView} setListView={setIsListView} />;
  }, [isListView, setIsListView]);

  return { isListView, viewToggleButtons };
};
