import {
  ComprehensiveStatusReport,
  FailedItemTypesReport,
  OnTimeRatioReport,
  ResponsesReport,
} from '@dakota/platform-client';
import { createSlice } from '@reduxjs/toolkit';

import {
  getComprehensiveStatusReport,
  getFailedItemTypesReport,
  getOnTimeRatioReport,
  getResponseDataReport,
  getResponsesReport,
} from './reportsActions';

export type ReportsState = {
  comprehensiveStatusReport?: ComprehensiveStatusReport;
  failedItemTypesReport?: FailedItemTypesReport;
  isLoadingComprehensiveStatusReport: boolean;
  isLoadingFailedItemTypesReport: boolean;
  isLoadingOnTimeReport: boolean;
  isLoadingResponseDataReport: boolean;
  isLoadingResponsesReport: boolean;
  onTimeReport?: OnTimeRatioReport;
  responsesReport?: ResponsesReport;
};

export const initialState: ReportsState = {
  comprehensiveStatusReport: undefined,
  failedItemTypesReport: undefined,
  isLoadingComprehensiveStatusReport: false,
  isLoadingFailedItemTypesReport: false,
  isLoadingOnTimeReport: false,
  isLoadingResponseDataReport: false,
  isLoadingResponsesReport: false,
  onTimeReport: undefined,
  responsesReport: undefined,
};

export const reportsSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(getComprehensiveStatusReport.fulfilled, (state, action) => {
      state.isLoadingComprehensiveStatusReport = false;
      state.comprehensiveStatusReport = action.payload;
    });
    builder.addCase(getComprehensiveStatusReport.pending, (state) => {
      state.isLoadingComprehensiveStatusReport = true;
    });
    builder.addCase(getComprehensiveStatusReport.rejected, (state) => {
      state.isLoadingComprehensiveStatusReport = false;
    });
    builder.addCase(getResponsesReport.fulfilled, (state, action) => {
      state.isLoadingResponsesReport = false;
      state.responsesReport = action.payload;
    });
    builder.addCase(getResponsesReport.pending, (state) => {
      state.isLoadingResponsesReport = true;
    });
    builder.addCase(getResponsesReport.rejected, (state) => {
      state.isLoadingResponsesReport = false;
    });
    builder.addCase(getFailedItemTypesReport.fulfilled, (state, action) => {
      state.isLoadingFailedItemTypesReport = false;
      state.failedItemTypesReport = action.payload;
    });
    builder.addCase(getFailedItemTypesReport.pending, (state) => {
      state.isLoadingFailedItemTypesReport = true;
    });
    builder.addCase(getFailedItemTypesReport.rejected, (state) => {
      state.isLoadingFailedItemTypesReport = false;
    });
    builder.addCase(getResponseDataReport.pending, (state) => {
      state.isLoadingResponseDataReport = true;
    });
    builder.addCase(getResponseDataReport.fulfilled, (state) => {
      state.isLoadingResponseDataReport = false;
    });
    builder.addCase(getResponseDataReport.rejected, (state) => {
      state.isLoadingResponseDataReport = false;
    });
    builder.addCase(getOnTimeRatioReport.pending, (state) => {
      state.isLoadingResponseDataReport = true;
    });
    builder.addCase(getOnTimeRatioReport.fulfilled, (state, action) => {
      state.isLoadingResponseDataReport = false;
      state.onTimeReport = action.payload;
    });
    builder.addCase(getOnTimeRatioReport.rejected, (state) => {
      state.isLoadingResponseDataReport = false;
    });
  },
  initialState,
  name: 'reports',
  reducers: {},
  selectors: {
    comprehensiveStatusReport: (state: ReportsState) =>
      state.comprehensiveStatusReport,
    failedItemTypesReport: (state: ReportsState) => state.failedItemTypesReport,
    isLoadingComprehensiveStatusReport: (state: ReportsState) =>
      state.isLoadingComprehensiveStatusReport,
    isLoadingFailedItemTypesReport: (state: ReportsState) =>
      state.isLoadingFailedItemTypesReport,
    isLoadingOnTimeReport: (state: ReportsState) => state.isLoadingOnTimeReport,
    isLoadingResponseDataReport: (state: ReportsState) =>
      state.isLoadingResponseDataReport,
    isLoadingResponsesReport: (state: ReportsState) =>
      state.isLoadingResponsesReport,
    onTimeReport: (state: ReportsState) => state.onTimeReport,
    responsesReport: (state: ReportsState) => state.responsesReport,
  },
});
