import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { IconButton } from '@mui/material';
import { clsx } from 'clsx';
import { MultilineInput } from 'components/MultilineInput';
import { Spinner } from 'components/Spinner';
import Tooltip from 'components/Tooltip';
import { useAppConfiguration } from 'hooks/useAppConfiguration';
import { FC } from 'react';
import resolveConfig from 'tailwindcss/resolveConfig';

import tailwindConfig from '../../../tailwind.config';
import { FileIcon } from './FileIcon';

const { theme: dakotaTheme } = resolveConfig(tailwindConfig);

export type UploadState = 'error' | 'pending' | 'uploaded' | 'uploading';

type FilePreviewProps = {
  description: string;
  file: File;
  index: number;
  onRemove: () => void;
  setDescription: (description: string) => void;
  state: UploadState;
  /**
   * When true, header will be displayed in red and editable text will be
   * disabled
   */
  warning?: boolean;
};

export const FilePreview: FC<FilePreviewProps> = ({
  description,
  file,
  index,
  onRemove,
  setDescription,
  state,
  warning = false,
}) => {
  const { getConfig } = useAppConfiguration();

  return (
    <div
      className={clsx(
        'border border-gray-200 rounded-lg divide-y *:px-2 group',
        { 'opacity-50': state === 'uploading' },
      )}
      data-testid='file-preview'
    >
      <div
        className={clsx(
          'flex items-center justify-between gap-2 text-sm py-1',
          'rounded-t-lg',
          warning || state === 'error'
            ? 'bg-red-light'
            : 'bg-gray-50 group-hover:bg-gray-100',
        )}
      >
        <div className='flex-1'>Attachment {index + 1}</div>
        {state === 'uploading' && <Spinner className='w-4' />}
        {state === 'uploaded' && (
          <CheckCircleIcon className='text-green-base w-4' />
        )}
        {state === 'error' && (
          <>
            <Tooltip title='Remove'>
              <IconButton aria-label='Remove' onClick={onRemove}>
                <TrashIcon className='w-4 text-red-base' />
              </IconButton>
            </Tooltip>
            <ExclamationCircleIcon
              aria-label='Upload error'
              className='text-red-dark w-4'
            />
          </>
        )}
        {state === 'pending' && (
          <Tooltip title='Remove'>
            <IconButton aria-label='Remove' onClick={onRemove}>
              <TrashIcon className='w-4 text-red-base' />
            </IconButton>
          </Tooltip>
        )}
      </div>
      <div className='flex justify-between items-center py-2'>
        <div className='flex-1 text-xs text-gray-500 flex flex-col gap-1'>
          <div className='max-w-64 truncate'>
            Filename: <span className='font-mono'>{file.name}</span>
          </div>
          <div>Size: {file.size} bytes</div>
        </div>
        <div className='flex-none'>
          <FileIcon file={file} />
        </div>
      </div>
      <div className='p-2'>
        <MultilineInput
          aria-label='Attachment description'
          maxLength={getConfig('AttachmentDescriptionMaxLength')}
          maxRows={6}
          minRows={1}
          onChange={setDescription}
          placeholder='Add an optional description'
          style={{
            borderColor: dakotaTheme.colors.gray[200],
            color: dakotaTheme.colors.gray[500],
          }}
          value={description}
        />
      </div>
    </div>
  );
};
